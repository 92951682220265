module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/saraa/restaurante_sur_gatsby/src/intl_","languages":["en","es"],"defaultLanguage":"es","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Restaurante Sur","short_name":"Restaurante Sur","start_url":"/","background_color":"#262525","theme_color":"#262525","display":"minimal-ui","icon":"src/images/favicon-16x16.png","icons":[{"src":"src/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"src/images/logo-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"src/images/apple-touch-icon.png","sizes":"180x180","type":"image/png","purpose":"any"},{"src":"src/images/favicon-32x32.png","sizes":"32x32","type":"image/png","purpose":"any"},{"src":"src/images/safari-pinned-tab.svg","sizes":"285x285","type":"image/svg","purpose":"any maskable"},{"src":"src/images/mstile-150x150.png","sizes":"270x270","type":"image/png","purpose":"any"},{"src":"src/images/favicon.ico","sizes":"48x48","type":"image/ico","purpose":"any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ce5a0b1a454b8e57fd7b946ab4a6838e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
